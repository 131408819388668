<template>
	<div class="position-relative">
		<div
			class="position-absolute h-100 bg-white w-100"
			style="top: 0; left: 0">
			<div>
				<div
					class="row"
					style="height: 35vh;">
					<div
						:style="backgroundStyles"
						class="col-12" />
				</div>
				<div
					class="row"
					style="height: 100%; max-height: 35vh !important;">
					<div class="col-12 bg-white" />
				</div>
			</div>
		</div>
		<div
			class="position-relative">
			<section
				:class="['xs', 'sm', 'md'].includes(windowWidth) ? '': 'p-5'"
				class="w-100 row no-gutters text-dark">
				<div class="col d-flex justify-content-center align-items-center">
					<div
						:class="['xs', 'sm', 'md'].includes(windowWidth) ? '': 'px-5'"
						class="container-xl mw-100">
						<h3
							:class="{
								'pt-5 mt-5': ['xs', 'sm'].includes(windowWidth),
								'pt-3 mt-5': windowWidth === 'md',
							}"
							class="h1 text-center text-white font-weight-bolder mb-0">
							{{ translate('buy_now') }}
						</h3>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'mb-0' : ''"
							class="card rounded-0 shadow border-0 p-0 mt-4">
							<div class="card-body p-4 m-0">
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? '' : 'no-gutters'"
									class="row">
									<div class="col-12">
										<!-- Main product -->
										<div v-if="mainProductLoading">
											<is-loading
												:loading="mainProductLoading"
												:loading-label="translate('loading')"
												:no-data-label="translate('data_not_found')" />
										</div>
										<div v-else>
											<!-- Image / data -->
											<div
												:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : ''"
												class="row">
												<div class="col-12 col-md-6 d-md-none">
													<!-- Product name / price -->
													<h2
														class="section-header"
														:class="subtitle.length > 0 ? 'mb-0' : ''"
														:style="productNameStyle">
														{{ title }}
													</h2>
													<h2
														v-if="subtitle.length > 0"
														class="p-0"
														style="font-size: 2.5rem !important;">
														{{ subtitle }}
													</h2>
													<!-- <p class="text-muted h3 font-weight-bold">
														{{ mainProductPrice }}
													</p>
													<div
														v-if="mainHasExchange"
														class="mx-auto exchange-text text-center medium d-flex align-items-end">
														{{ mainExchangePrice }}*
													</div>
													<div
														v-if="mainHasExchange"
														class="mx-auto exchange-text text-left small d-flex align-items-end">
														{{ translate('exchange_disclaimer') }}
													</div> -->
													<p class="text-muted h6 font-weight-bold">
														{{ mainProductData.presentation }}
													</p>
													<div class="border-bottom my-4 px-5" />
												</div>
												<!-- Image Component -->
												<div
													class="col-12 col-md-6"
													:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'px-3' : 'pl-4 pr-5'">
													<image-carousel
														:images="imgCarousel"
														:group-to-show="getGroupToShow"
														:enable-zoom="true"
														:highlight-color="highlightColor"
														:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-5'" />
												</div>
												<!-- Product price/sizes/colors/qty -->
												<div
													:class="['xs', 'sm'].includes(windowWidth) ? '' : 'pl-0'"
													class="col-12 col-md-6 d-flex flex-column justify-content-center ">
													<!-- Product name / price -->
													<div :class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''">
														<h2
															class="section-header p-0"
															:class="subtitle.length > 0 ? 'mb-0' : ''"
															:style="productNameStyle">
															{{ title }}
														</h2>
														<h2
															v-if="subtitle.length > 0"
															class="p-0"
															style="font-size: 2.5rem !important;">
															{{ subtitle }}
														</h2>
														<!-- Prices -->
														<!-- <p class="text-muted h3 font-weight-bold">
															{{ mainProductPrice }}
														</p>
														<div
															v-if="mainHasExchange"
															class="mx-auto exchange-text text-center medium d-flex align-items-end">
															{{ mainExchangePrice }}*
														</div>
														<div
															v-if="mainHasExchange"
															class="mx-auto exchange-text text-left small d-flex align-items-end">
															{{ translate('exchange_disclaimer') }}
														</div> -->
														<p class="text-muted h6 font-weight-bold">
															{{ mainProductData.presentation }}
														</p>
														<div class="border-bottom mr-5 my-3 pr-5" />
													</div>
													<!-- Flavors -->
													<div
														v-if="getFlavor.length > 0"
														:class="['xs', 'sm'].includes(windowWidth) ? '' : ''"
														class="row my-2">
														<div class="col">
															<div class="row no-gutters mb-3">
																<div class="col">
																	<p class="m-0 font-weight-bolder text-gray">
																		{{ translate('flavor') }}
																	</p>
																</div>
															</div>
															<div
																:class="['xs', 'sm'].includes(windowWidth) ? '' : 'no-gutters'"
																class="row">
																<div
																	v-for="(item, index) in getFlavor"
																	:key="index"
																	:class="{
																		'pl-0 pr-2': !['xs', 'sm'].includes(windowWidth),
																		'pr-1': ['xs', 'sm'].includes(windowWidth) && !(index % 2),
																		'pl-1 ': ['xs', 'sm'].includes(windowWidth) && index % 2,
																	}"
																	class="col-6 col-md-auto d-md-inline">
																	<div
																		:class="selectedProduct.flavor === item ? 'config-color-active' : 'config-color'"
																		:style="configurationBtnStyles(item)"
																		class="btn btn-outline-primary config-btn text-decoration-none rounded-0 p-1 mb-2"
																		@click="selectedProduct.flavor = item">
																		<p class="font-weight-bold mb-0 p-1 h6">
																			{{ translate(`${item}_flavor`) }}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<!-- Product out of stock -->
													<div
														v-if="typeof outOfStockProducts[mainProductData.code_name] !== 'undefined'"
														class="row"
														:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'px-3'">
														<div class="align-self-center w-auto">
															<b-alert
																show
																variant="warning"
																class="mb-0">
																{{ translate(`${outOfStockProducts[mainProductData.code_name]}_out_of_stock`) }}
															</b-alert>
														</div>
													</div>
													<!-- Product qty/add btn -->
													<div class="row mt-4 mb-3">
														<div
															class="col-auto align-self-center w-auto my-custom-spinner">
															<number-input-spinner
																v-model="selectedProduct.quantity"
																:mouse-down-speed="500"
																:min="MIN_QTY"
																:max="MAX_QTY"
																button-class="vnis__button bg-primary-alt"
																:input-class="'vnis__input vnis-custom-input-width'"
																:integer-only="true"
																class="vnis-smaller" />
														</div>
														<div
															:class="['xs', 'sm'].includes(windowWidth) ? 'text-right' : ''"
															class="col align-self-center">
															<div
																:class="addToCartDisabled ? 'disabled' : ''"
																:style="makeHighlightStyle('background-color')"
																class="btn p-2 text-white pointer custom-btn custom-primary-btn"
																style="font-weight: 600;"
																@click="addProduct(getProductSku, getProductOptions(getProductSku));">
																<template v-if="addProductsLoading">
																	<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
																</template>
																<template v-else>
																	{{ translate('add_to_cart') }}
																</template>
															</div>
														</div>
													</div>
													<!-- Free shipping -->
													<!-- <div
														:class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : ''"
														class="row mt-3">
														<div class="col">
															<span class="text-gray h5 font-weight-bolder">{{ translate('free_shipping') }}</span>
														</div>
													</div> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import cookie from 'vue-cookie';
import NumberInputSpinner from 'vue-number-input-spinner';
import ImageCarousel from '@/components/ProductImageCarousel';
import isLoading from '@/components/Loading';
import WindowSizes from '@/mixins/WindowSizes';
import { MAX_QTY, MIN_QTY } from '@/settings/Cart';
import { NOT_FOUND } from '@/settings/Errors';
import { NON_AUTH_HIDDEN_PRODUCTS, PRODUCT_DEFAULT_PRICE, PRODUCT_OVERRIDE_PRICE } from '@/settings/Products';
import { Products as ProductsTranslations, Store } from '@/translations';
import Cart from '@/util/Cart';
import EventBus from '@/util/eventBus';
import { trackEvent } from '@/util/GoogleTagManager';
import Products from '@/util/Products';
import Color from '../mixins/Color';
import PublicStore from '@/mixins/PublicStore';

export default {
	name: 'BuySection',
	messages: [Store, ProductsTranslations],
	components: {
		isLoading,
		ImageCarousel,
		NumberInputSpinner,
	},
	mixins: [Color, WindowSizes, PublicStore],
	props: {
		background: {
			type: String,
			required: true,
		},
		product: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		subtitle: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			NOT_FOUND,
			mainProduct: new Products(),
			addProducts: new Cart(),
			alert: new this.$Alert(),
			MIN_QTY,
			MAX_QTY,
			selectedProduct: {
				quantity: MIN_QTY,
				flavor: '',
			},
			outOfStockProducts: {},
		};
	},
	computed: {
		addToCartDisabled() {
			return this.addProductsLoading || this.$user.details().active_multi_payment;
		},
		mainProductData() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const response = {
					sku: data.attributes.sku,
					code_name: data.attributes.code_name,
					price: this.getProductPrice(data.attributes),
					image: data.attributes.image,
					presentation: this.translate(`${data.attributes.code_name}_presentation`),
					children: data.attributes.children,
					thumbnail: data.attributes.thumbnail,
					exchange: data.attributes.exchange,
					exchange_price: data.attributes.exchange_price,
					tabsInfo: {
						details: this.translate(`${data.attributes.code_name}_description`),
						// more_information: '',
					},
				};
				return response;
			} catch (error) {
				return [];
			}
		},
		mainProductPrice() {
			try {
				if (Object.keys(this.mainProductData).length > 0) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price) {
						return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price;
					}
					return this.mainProductData.children[Object.keys(this.getProductSku)[0]].price;
				}
				return this.mainProductData.price;
			} catch (e) {
				return this.mainProductData.price;
			}
		},
		mainHasExchange() {
			try {
				if (Object.keys(this.mainProductData).length > 0) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
						return !!PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
					}
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange : this.mainProductData.exchange;
				}
				return false;
			} catch (e) {
				return false;
			}
		},
		mainExchangePrice() {
			try {
				if (this.mainHasExchange) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
						return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
					}
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange_price : this.mainProductData.exchange_price;
				}
				return '';
			} catch (e) {
				return '';
			}
		},
		mainProductLoading() {
			return !!this.mainProduct.data.loading;
		},
		getGroupToShow() {
			return Object.keys(this.getProductSku)[0];
		},
		imgCarousel() {
			try {
				const { data } = this.mainProduct.data.response.data;
				return data.attributes.extra_images;
			} catch (error) {
				return {};
			}
		},
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		getFlavor() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availableFlavors = Object.keys(data.attributes.configurations.flavor);

				// Move lemon drop to the last option if it's available
				const lemonDropIndex = availableFlavors.indexOf('1001');
				if (lemonDropIndex !== -1) {
					availableFlavors.splice(lemonDropIndex, 1);
					availableFlavors.push('1001');
				}

				return availableFlavors;
			} catch (error) {
				return [];
			}
		},
		getProductSku() {
			try {
				let sku = [this.mainProductData.sku];
				if (this.selectedProduct.flavor) {
					sku.push(this.selectedProduct.flavor);
				}
				sku = sku.join('-');
				return { [sku]: this.selectedProduct.quantity };
			} catch (error) {
				return [];
			}
		},
		backgroundStyles() {
			const styles = this.background.startsWith('#') ? [
				`background-color: ${this.background}`,
			] : [
				`background-image: url('${this.background}');`,
				'background-size: cover;',
				'background-position: center;',
				'background-repeat: none;',
				this.backgroundHeight,
			];

			return styles.join(' ');
		},
		productNameStyle() {
			const styles = [
				this.makeHighlightStyle('color'),
				'font-size: 2.5rem !important;',
			];

			return styles.join(' ');
		},
	},
	watch: {
		async country() {
			this.initProductInfo();
		},
	},
	mounted() {
		if (!this.showPublicStore) {
			this.$router.replace({ name: 'Store' }).catch(() => {});
			return;
		}
		this.initProductInfo();
	},
	methods: {
		initProductInfo() {
			const productName = this.product;
			return this.mainProduct.getProductDetails(productName).then((response) => {
				const { sku } = response.attributes;

				if (!this.$user.auth() && NON_AUTH_HIDDEN_PRODUCTS.includes(sku)) {
					this.$router.push({ name: 'Store' });
				}

				[this.selectedProduct.flavor] = [this.getFlavor[0]];
			}).catch((error) => {
				if (this.NOT_FOUND.includes(error.status)) this.$router.push({ name: 'Store' });
			});
		},
		getProductOptions(products) {
			let image = this.mainProductData.thumbnail;
			if (Object.keys(this.mainProductData.children).length) {
				const { children } = this.mainProductData;
				Object.keys(products).forEach((sku) => { image = children[sku].thumbnail; });
			}
			const options = { image };
			return options;
		},
		async addProduct(products) {
			if (!this.addToCartDisabled) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products, sponsor_id: this.$replicated.replicatedSponsor() });
					trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
		getProductPrice(product, priceKey = 'price') {
			const { price, sku } = product;

			if (PRODUCT_OVERRIDE_PRICE[sku]?.[priceKey]) {
				return PRODUCT_OVERRIDE_PRICE[sku][priceKey];
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]?.[priceKey]) {
				return PRODUCT_DEFAULT_PRICE[sku][priceKey];
			}

			return price;
		},
		configurationBtnStyles(item) {
			const isSelected = this.selectedProduct.flavor === item;

			const styles = {
				'border-color': `${this.highlightColor};`,
			};

			if (['xs', 'sm', 'md'].includes(this.windowWidth)) {
				styles.width = '100%';
			}

			if (isSelected) {
				styles['background-color'] = this.highlightColor;
				styles.color = '#FFFFFF';
			} else {
				styles['--config-color'] = '#FFFFFF';
			}

			return Object.entries(styles)
				.map(([prop, value]) => `${prop}: ${value};`)
				.join(' ');
		},
	},
};
</script>
<style scoped>
.custom-btn.custom-primary-btn {
	width: 150px;
	border-radius: 25px;
}
.config-btn > p {
	line-height: 1.2 !important;
}
.config-color:hover {
	color: var(--config-color) !important;
}
.config-color-active > *, .config-color-active:hover {
	color: #FFFFFF !important;
}
.my-custom-spinner /deep/ .vnis__button:hover {
	background: var(--product-primary-color) !important;
}
</style>
<style>
#theme .vnis-custom-input-width{
	width: 40px;
}
</style>
