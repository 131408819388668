<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="card border-0 rounded-0 p-0 mb-4"
		style="background-color: #f5f5f5">
		<div
			:class="textCentered ? 'text-center' : ''"
			class="card-body py-4 pl-4 pr-0">
			<div v-html="content" />
			<slot />
		</div>
	</div>
</template>
<script>
export default {
	name: 'HeaderBlock',
	props: {
		content: {
			type: String,
			default: '',
		},
		textCentered: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
