<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section :background-style="backgroundStyle ? backgroundStyle : ''">
		<div>
			<div
				v-for="(iconRow, rowIndex) in iconRows"
				:key="`iconRow-${rowIndex}`"
				:class="rowIndex === iconRows.length - 1 ? '' : 'mb-5'"
				class="row justify-content-center">
				<div
					v-for="(icon, iconIndex) in iconRow"
					:key="`iconCol-${iconIndex}`"
					class="col d-flex flex-column align-items-center">
					<object
						v-if="icon.file.endsWith('.svg')"
						type="image/svg+xml"
						:data="icon.file"
						height="50%">
						Your browser does not support SVG.
					</object>
					<img
						v-else
						class="img-fluid"
						style="max-height: 100px; !important"
						:src="icon.file">
					<span
						:class="smallerTexts && !['xs', 'sm'].includes(windowWidth) ? 'h6' : 'h4'"
						class="mt-3 text-center text-gray"
						:style="textStyle"
						style="max-width: 190px;">
						<div
							class="d-inline"
							v-html="icon.text" />
						<a
							v-if="includeAddendum"
							href="#fda_msg">
							<sup :class="addendumClass">‡</sup>
						</a>
					</span>
				</div>
			</div>
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import PageSection from './PageSection';

export default {
	name: 'IconsSection',
	components: {
		PageSection,
	},
	mixins: [WindowSizes],
	props: {
		backgroundStyle: {
			type: String,
			default: '',
		},
		icons: {
			type: Array,
			required: true,
		},
		maxCols: {
			type: [String, Number],
			required: true,
		},
		includeAddendum: {
			type: Boolean,
			default: false,
		},
		smallerTexts: {
			type: Boolean,
			default: false,
		},
		textStyle: {
			type: String,
			default: '',
		},
		addendumClass: {
			type: String,
			default: 'product-primary-color',
		},
	},
	computed: {
		iconRows() {
			const { windowWidth } = this;
			const colsPerWindowWidth = {
				xs: 1,
				sm: 2,
				md: 2,
				lg: 3,
				xl: this.maxCols,
			};
			const rows = [];

			this.icons.forEach((icon) => {
				if (rows.length === 0 || rows[rows.length - 1].length >= colsPerWindowWidth[windowWidth]) {
					rows.push([]);
				}
				rows[rows.length - 1].push(icon);
			});
			return rows;
		},
	},
};
</script>
